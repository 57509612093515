
function Hero(){
    return(
        <div id="hero">
            <div id="hero-content" className="content">
                <div id="hero-grid">
                    <h1>Página em<br/><span className="purple">construção!</span></h1>
                    <p>Porém os links já funcionam :)</p>
                </div>
            </div>
        </div>
    );
}

export default Hero;